<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      title="Modal title 2"
      size="xl"
      color="primary"
      :class="ModalName"
    >
      <template #header>
        <h6>Concept registration</h6>
        <CButtonClose @click="ChangeModal()" id="btnCloseModal" class="text-white" v-if="viewer === false" />
      </template>
 
      <div>
        <CRow>
          <CCol>
            <h5 class="subhead-modal">
              Creator detail
            </h5>
          </CCol>
          <CCol>
            <div class="subhead-modal-action">
              <CLink
                class="card-header-action btn-minimize"
                @click="formCollapsed = !formCollapsed"
              >
                <CIcon name="cil-chevron-bottom" v-if="formCollapsed" />
                <CIcon name="cil-chevron-top" v-else />
              </CLink>
            </div>
          </CCol>
        </CRow>
        <CCollapse :show="formCollapsed">
          <CRow>
            <CCol md="6">
              <CInput
                label="Creator"
                horizontal
                readonly="readonly"
                v-model="Creator"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Create Date"
                horizontal
                readonly="readonly"
                v-model="CreateDate"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CInput
                label="E-mail"
                horizontal
                readonly="readonly"
                v-model="Email"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Business unit"
                horizontal
                readonly="readonly"
                v-model="CreatorBU"
              />
            </CCol>
          </CRow>
        </CCollapse>

        <hr />
      </div>

      <CRow>
        <h5 class="subhead-modal pl-3">
          Market / Customer
        </h5>
      </CRow>

      <CRow>
        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">For Which Industry <span class="error">*</span></label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              ref ="RefConcepVSelect"
              v-model="DataObj.ConceptCusIndustry"
              :value.sync="DataObj.ConceptCusIndustry"
              :options="IndustryOptions"
              placeholder="-- Select --"
              @input="IndustryOnChange($event)"
              :disabled="disabled"
              required
            />
          </div>
        </CCol>

        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">Segment</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="DataObj.ConceptCusSegment"
              :value.sync="DataObj.ConceptCusSegment"
              :options="SegmentOptions"
              placeholder="-- Select --"
              @input="SegmentOnChange($event)"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">Sub Segment</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="DataObj.ConceptCusSubSegment"
              :value.sync="DataObj.ConceptCusSubSegment"
              :options="SubSegmentOptions"
              placeholder="-- Select --"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>

      <hr />
      <br />

      <div>
        <CRow>
          <h5 class="subhead-modal pl-3">
            Link from which ideas?
          </h5>
          <!-- {{ideaSelected}} -->
        </CRow>

        <CRow>
          <CCol md="6 form-group form-row">
            <div class="col-md-3">
              <label class="">Idea No.</label>
            </div>
            <div class="col-md-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <CButton color="search" size="sm" @click="searchIdea()" v-if="viewmode !== 'view'">
                    <CIcon name="cil-magnifying-glass" />Search
                  </CButton>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol md="12">
            <CDataTable
              :items="ideaSelected"
              :fields="ideaFieldsSelected"
              sorter
            >
              <template #IdeaNo="{item}">
                <td>
                  <CLink class="text-primary" @click="NewTapIdeaOrInsight('viewer', item.IdeaNo)" >
                    {{ item.IdeaNo }}
                  </CLink>
                </td>
              </template>

              <template #Insightdetail="{item}">
                <td>
                  <table class="tableInsightdetail">
                    <tr
                      v-for="insight in item.InsightLinks"
                      :key="insight.InsightNo"
                    >
                      <td>
                        <CLink class="text-primary" @click="NewTapIdeaOrInsight('viewer', insight.InsightNo)">
                          {{ insight.InsightNo }}
                        </CLink>
                      </td>
                      <td>{{ insight.InsightDetail }}</td>
                    </tr>
                  </table>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </div>
      <hr />

      <div v-if="showIdeationEvent">
        <CRow>
          <CCol md="12">
            <CRow>
              <h5 class="subhead-modal pl-3">
                Ideation event detail
              </h5>
            </CRow>
            <CRow>
              <CCol class="form-group form-row">
                <div class="col-md-3">
                  <label class="">Industry</label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    v-model="IdeaIndustry"
                    :value.sync="IdeaIndustry"
                    :options="IndustryOptions"
                    placeholder="-- Select --"
                    disabled
                  />
                </div>
              </CCol>

              <CCol class="form-group form-row">
                <div class="col-md-3">
                  <label class="">Group</label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    v-model="IdeaGroup"
                    :value.sync="IdeaGroup"
                    :options="groupOptions"
                    placeholder="-- Select --"
                    disabled
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="form-group form-row" md="6">
                <div class="col-md-3">
                  <label class="">Event date</label>
                </div>
                <div class="col-md-9 pr-0">
                  <DatePicker
                class="custom-input-date"
                v-model="IdeaEventDate"
                :first-day-of-week="2"
                :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="bg-white border px-2 py-1 rounded"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="YYYY/MM/DD"
                    readonly
                    disabled
                  />
                </template>
              </DatePicker>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <hr />
      </div>
      <br />

      <CRow>
        <h5 class="subhead-modal pl-3">
          Concept detail
        </h5>
      </CRow>
      <CRow>
        <CCol md="6">
          <CTextarea
            ref="TAIDEA"
            label="IDEA"
            rows="3 "
            horizontal
            placeholder="What is your idea ?"
            DataObj.ConceptCusSegment
            v-model="DataObj.ConceptIdea"
            required
            was-validated
          />
        </CCol>
        <CCol md="6">
          <CTextarea
            ref="TAToSolve"
            label="To solve"
            rows="3 "
            horizontal
            placeholder="What is problem to be solved ?"
            v-model="DataObj.ConceptToSolve"
            required
            was-validated
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <CTextarea
            ref="TAcWhom"
            label="Whom"
            rows="3 "
            horizontal
            placeholder="who want this problem solved ?"
            v-model="DataObj.ConceptWhom"
            required
            was-validated
          />
        </CCol>
        <CCol md="6">
          <CTextarea
            ref="TACurrentSolutions"
            label="Current solutions"
            rows="3 "
            horizontal
            v-model="DataObj.ConceptCurrentSolution"
            required
            was-validated
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CTextarea
            ref="TADetailOfNew"
            label="Detail of new product & services"
            placeholder="(Material, Design, Process)"
            rows="3 "
            horizontal
            v-model="DataObj.ConceptDetailOfProd"
            required
            was-validated
          />
        </CCol>
        <CCol md="6">
          
          <CInputFile multiple custom label="Attach files" horizontal 
          :ref="'inputfile'" 
          :description="'(*Only .pdf, .doc, .xls, .ppt, .jpg, .png maximum size 5 MB)'" 
          v-on:change="fileChange($event)" />
          
          <div class="cpl-10" v-for="file in DataObj.fileExists" :key="file.AttachmentID">
            <div v-if="!file.IsDelete">
              <CLink class="text-primary" @click="OpenFile(file.AttachmentName, true)">
                {{ file.AttachmentName.split("/")[1] }}
              </CLink>
              <CButton class="btn-sm" @click="ConfirmDeleteFile(file)" v-if="viewmode !== 'view'" color="danger">
                Delete
              </CButton>
            </div>
          </div>

          <div class="cpl-10" v-for="(file, key) in DataObj.files" :key="file.name">
            <CLink class="text-primary" @click="OpenFile(file)">
                {{ file.name }}
              </CLink>
            <CButton color="danger" size="sm" @click="ConfirmDeleteFileBeforeSave(key)">
                Delete
            </CButton>
          </div>
          
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CTextarea
            ref="TAKeyResourceNeeded"
            label="Key resource needed"
            rows="3 "
            horizontal
            v-model="DataObj.ConceptNeed"
            required
            was-validated
          />
        </CCol>
        <CCol md="6">
          <CInput
            ref="TBConceptname"
            label="Concept name"
            label.innerHTML ="<span class='error'>*</span>"
            horizontal
            v-model="DataObj.ConceptName"
            required
            was-validated
          />
        </CCol>        
      </CRow>
      <CRow>
        <CCol md="6">
          <CInput
            label="Concept No"
            horizontal
            v-model="DataObj.ConceptNo"
            readonly
          ></CInput>
        </CCol>
        <CCol class="form-group form-row" md="6" v-c-tooltip="{content: 'BU ที่เกี่ยวข้องกับ Concept นี้', placement: 'top'}">
           <div class="col-md-3">
              <label class="">Related business unit<span class="error">*</span></label>
            </div>
            <div class="col-md-9 pr-0">
              <v-select 
                ref="RefRelateBuSelect"
                v-model="DataObj.BU"
                :value.sync="DataObj.BU"
                :options="buOptions"
                placeholder="-- Select --"
                :disabled="disabled"
                required/>
            </div>
        </CCol>
      </CRow>

      <hr />
      
      <CRow>
        <h5 class="subhead-modal pl-3">
          Concept prioritization
        </h5>
      </CRow>

      <br />

      <CRow>
        <h6 class="subhead-modal pl-3">
          Market Attractiveness
        </h6>
      </CRow>

      <table class="table table-hover">
        <thead>
          <tr width="100%">
            <th>No</th>
            <th width="40%">Criteria</th>
            <th class="text-center">Weight</th>
            <th width="20%" class="text-center">Score</th>
            <th width="20%">Assumption</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in marketAttractiveness" :key="obj.ScoreID">
            <td>
              {{ key + 1 }}
            </td>
            <td>
              {{ obj.ScoreCriteria }}
            </td>
            <td class="text-center">
              {{ obj.ScoreWeight }}
            </td>
            <td class="text-center">
              <!-- {{obj.ScoreSelect}} -->
              <CButton
                v-for="(item, itemkey) in obj.ScoreDetail"
                :key="item.ScorePointID"
                :color="
                  itemkey == 0 ? 'light' : itemkey == 1 ? 'secondary' : 'dark'
                "
                v-c-tooltip.hover="item.ScorePointDesc"
                size="lg"
                :groupname="'idgroup' + item.ScoreID"
                @click="
                  clickScore('idgroup' + item.ScoreID, item.ScorePoint, obj)
                "
                :class="
                  item.ScorePoint === obj.ScoreSelect ? 'btnSelectedScore' : ''
                "
              >
                {{ item.ScorePoint }}
              </CButton>
            </td>
            <td><CTextarea rows="3 " v-model="obj.Assumption" /></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">
              Auto-Cal Total Sum : {{ totalMarketAttractiveness }}
            </td>
            <td><CButton color="danger" size="sm" @click="ReScore(true)">Reset</CButton></td>
          </tr>
        </tfoot>
      </table>

      <CRow>
        <h6 class="subhead-modal pl-3">
          Project Relatedness
        </h6>
      </CRow>

      <table class="table table-hover">
        <thead>
          <tr width="100%">
            <th>No</th>
            <th width="40%">Criteria</th>
            <th class="text-center">Weight</th>
            <th width="20%" class="text-center">Score</th>
            <th width="20%">Assumption</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in projectRelatedness" :key="obj.ScoreID">
            <td>
              {{ key + 1 }}
            </td>
            <td>
              {{ obj.ScoreCriteria }}
            </td>
            <td class="text-center">
              {{ obj.ScoreWeight }}
            </td>
            <td class="text-center">
              <CButton
                v-for="(item, itemkey) in obj.ScoreDetail"
                :key="item.ScorePointID"
                :color="
                  itemkey == 0 ? 'light' : itemkey == 1 ? 'secondary' : 'dark'
                "
                v-c-tooltip.hover="item.ScorePointDesc"
                size="lg"
                :groupname="'idgroup' + item.ScoreID"
                @click="
                  clickScore('idgroup' + item.ScoreID, item.ScorePoint, obj)
                "
                :class="
                  item.ScorePoint === obj.ScoreSelect ? 'btnSelectedScore' : ''
                "
              >
                {{ item.ScorePoint }}
              </CButton>
            </td>
            <td><CTextarea rows="3 " v-model="obj.Assumption" /></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">
              Auto-Cal Total Sum : {{ totalProjectRelatedness }}
            </td>
            <td><CButton color="danger" size="sm" @click="ReScore(false)">Reset</CButton></td>
          </tr>
        </tfoot>
      </table>
      <CRow v-show="showChart" >
        <CCol md="3"></CCol>
        <CCol md="9">
          <!-- <ScatterChart
            :chartData.sync="mydata"
            @datacollection-updated="mydata = $event"
            :options.sync="myOption"
          ></ScatterChart> -->

          <vue-apex-charts
            type="scatter"
            :options="chartOptions"
            :series="chartSeries"
            width="500"
            height="500"
          ></vue-apex-charts>
        </CCol>
      </CRow>

      <template #footer>
          <p ref="PwarningCanNotApproveCon" class="text-danger">Waiting for Idea Approve</p>
          <CButton ref="BtnApproveCon" @click="K2Action('Approve')" id="btnApprove" color="success" v-if="userRole === 'Admin' && viewmode !== 'create' && item !== null && item !== undefined && (viewmode === 'edit' && item.ConceptStatus === 'Manager' || viewmode === 'view' && item.ConceptStatus === 'Manager')">
            Approve
          </CButton>
          <CButton ref="BtnRejectCon" @click="K2Action('Reject')" id="btnReject" style="background-color:#f7cc54" v-if="userRole === 'Admin' && viewmode !== 'create' && item !== null && item !== undefined && (viewmode === 'edit' && item.ConceptStatus === 'Manager' || viewmode === 'view' && item.ConceptStatus === 'Manager')">
            Reject
          </CButton>
          <!-- <CButton
            @click="ChangeModal()"
            color="dark"
            v-if="userprofile.Role == 'admin'"
            >Terminate
          </CButton> -->
          <CButton @click="K2Action('Submit')" v-if="viewmode === 'create' || (viewmode !== 'view' && (item !== null && item !== undefined && item.ConceptStatus !== 'Completed' &&  item.ConceptStatus !== 'Manager'))" color="submit-custom">
            Submit
          </CButton> 
          <CButton @click="DeleteData()" color="danger" v-if="viewmode !== 'create' && viewmode !== 'view' && (item !== null  && item !== undefined && item.ConceptStatus === 'Draft')">
            Delete
          </CButton>
          <CButton @click="SaveData('Save')" color="primary" v-if="viewmode === 'create' || (viewmode === 'edit' && item !== null  && item !== undefined && item.ConceptStatus === 'Draft')">
            Save Draft
          </CButton>
          <CButton @click="ChangeModal()" id="btnCancel" color="secondary" v-if="viewer === false" >
            Cancel
          </CButton>

      </template>
    </CModal>
    <!-- Modal  end-->

    <CModal
      :show.sync="showIdeaSelectModal"
      :close-on-backdrop="false"
      title="Select Idea"
      size="xl"
      color="primary"
    >
      <CDataTable
        ref ="ideaTable"
        :items="ideaData"
        :fields="ideaFields"
        table-filter
        items-per-page-select
        :items-per-page="5"
        hover
        sorter
        pagination
      >
        <template #selectbox="{item}">
          <td>
            <CInputCheckbox
              :name="`IsSelected${item.IdeaNo}`"
              :checked="item.IsSelected"
              @click="ideaUpdateIsSelected(item.IdeaNo, $event)"
              group="ideaCheckbox"
              :inputid="item.IdeaNo"
            />
          </td>
        </template>
        <template #status="{item}">
          <td>
            <CBadge :color="getBadge(item.status)">
              {{ item.status }}
            </CBadge>
          </td>
        </template>
      </CDataTable>

      <template #footer>
        <CButton @click="showIdeaSelectModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="ideaConfirmselected()" color="primary"
          >Confirm selected</CButton
        >
      </template>
    </CModal>
    <CModal
      :show.sync="confirmSubmitConceptModal"
      :close-on-backdrop="false"
      title="Submit Concept?"
      color="primary"
      centered
    >
      <!-- Which business unit related to this concept? -->
      Would you like to submit concept?
      <template #header>
        <h6 class="modal-title">Submit Concept</h6>
        <CButtonClose
          @click="confirmSubmitConceptModal = false"
          class="text-white"
        />
      </template>
      <!-- <CRow>
        <br />
        <CCol class="form-group form-row">
           <div class="col-md-3">
              <label class="">Business unit </label>
            </div>
            <div class="col-md-9 pr-0">
              <v-select 
                v-model="DataObj.BU"
                :value.sync="DataObj.BU"
                :options="buOptions"
                placeholder="-- Select --"
                :disabled="disabled"
                required
              />
            </div>
        </CCol>
      </CRow> -->
      <template #footer>
        <CButton @click="CancelSubmit()" color="secondary">No</CButton>
        <CButton @click="SaveData('Submit')" color="primary">Yes</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="confirmApproveConceptModal"
      :close-on-backdrop="false"
      title="Approve Concept?"
      size="sm"
      color="primary"
      centered
    >
      Would you like to approve concept?
      <template #header>
        <h6 class="modal-title">Approve Concept</h6>
        <CButtonClose
          @click="confirmApproveConceptModal = false"
          id="btnCloseApproveModal"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton @click="CancelApprove()" id="btnApproveNo" color="secondary">No</CButton>
        <CButton @click="SaveData('Approve')" id="btnApproveYes" color="primary">Yes</CButton>
      </template>
    </CModal>

    <RejectModal 
      :showModal.sync="showRejectModal"
      @RejectModal-Updated="showRejectModal = $event"
      :rejectModel.sync="rejectModel"
      @close-modal="CloseFromRejectModal()"
    />        

    <CModal
      :show.sync="previewImgModal"
      :close-on-backdrop="false"
      title="Preview"
      size="lg"
      color="primary"
      centered
    >
      <template #header>
        <h6 class="modal-title">Preview</h6>
        <CButtonClose
          @click="previewImgModal = false"
          class="text-white"
        />
      </template>
      <CRow>        
        <CCol class="form-group form-row">
          <div id="preview">
            <img v-if="urlImage" :src="urlImage" />
          </div>
        </CCol>
      </CRow>
      <template #footer>
        <CButton id ="btnClosePreview" @click="previewImgModal = false" color="secondary">Close</CButton>
      </template>
    </CModal>

    <!-- <ScoreTable /> -->
    <Spinner :is-show="isSpinner = hideLoader"/>
  </div>  
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DatePicker } from "v-calendar";
import VueApexCharts from "vue-apexcharts";
import store from "../../store";
import axios from "axios";
import { uuid } from "vue-uuid";
import RejectModal from "../RejectModal";
import Spinner from "../../components/Spinner";

var URL_GetByID = store.getters.URL + "/api/IdeationConcept/getbyid";
var URL_Insert = store.getters.URL + "/api/IdeationConcept/insert";
var URL_Update = store.getters.URL + "/api/IdeationConcept/update";
var URL_Delete = store.getters.URL + "/api/IdeationConcept/delete";
var URL_Upload = store.getters.URL + "/api/IdeationConcept/uploadfile";
var URL_GetFilesByDoc = store.getters.URL + "/api/IdeationAttachment/getbydocid";
var URL_OpenFile = store.getters.URL + "/api/IdeationAttachment/openfile";
var URL_GetGroup = store.getters.URL + "/api/MasterGroup/getall";
var URL_GetBu = store.getters.URL + "/api/MasterBu/getall";

const today = new Date();

const ideaFields = [
  {
    key: "selectbox",
    label: "Select",
    _style: "width:1%",
    sorter: false,
    filter: false
  },
  { key: "IdeaNo", label: "Idea No." },
  { key: "IdeaIndustry", label: "Industry" },
  { key: "IdeaDetail", label: "Idea detail" },
  { key: "IdeaCreator", label: "Creator" },
  { key: "IdeaCreateDate", label: "Create Date" },
  { key: "IdeaStatus", label: "Status" }
];

const ideaFieldsSelected = [
  { key: "IdeaNo", label: "Idea No." },
  { key: "IdeaDetail", label: "Idea detail" },
  { key: "IdeaStatus", label: "Status" },
  {
    key: "Insightdetail",
    label: "Insight No. and Insight detail",
    sorter: false,
    filter: false
  }
];

export default {
  name: "ConceptModal",
  components: {
    vSelect,
    DatePicker,
    VueApexCharts,
    RejectModal,
    Spinner
  },
  data() {
    return {
      ModalName:"ConceptModal",
      SelectInsightModal: false,
      isviewDocumentStatus: true,
      formCollapsed: true,

      showInsightSelectModal: false,
      showIdeationEvent: false,
      showChart:false,

      showIdeaSelectModal: false,
      ideaFields,
      ideaData: [],
      ideaFieldsSelected,

      marketAttractiveness: [],
      projectRelatedness: [],
      TempDataEdit: [],

      urlImage: null,
      previewImgModal: false,
      ideaDate: "",

      MarketScoreGroup: "Market",
      ProjectScoreGroup: "Project",
      CreateUser: "",
      Creator: "",
      CreateDate: "",
      Email: "",
      CreatorBU: "",
      BU:"",      

      DataObj: this.NewObj(null),

      Datadate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),

      buOptions: [{ value: null, label: "--Select--" }],
      InsightList: [],
      IndustryOptions: [{ value: null, label: "--Select--" }],
      SegmentList: [],
      SegmentOptions: [{ value: null, label: "--Select--" }],
      SubSegmentList: [],
      SubSegmentOptions: [{ value: null, label: "--Select--" }],
      groupOptions: [{ value: "", label: "--Select--" }],
      IdeaGroup : "",
      IdeaIndustry : "",
      IdeaEventDate : "",

      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      // userprofile: JSON.parse(sessionStorage.getItem("userprofile")),
      // userToken: JSON.parse(sessionStorage.getItem("IdeationToken")),

      chartOptions: {
        chart: {
          type: "scatter",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          tickAmount: 10,
          min: 0,
          max: 100,
          labels: {
            align: "center"
          },
          title: {            
            text: "Project relatedness",
          }
        },
        yaxis: {
          showForNullSeries: false,
          tickAmount: 10,
          min: 0,
          max: 100,
          labels: {
            align: "center"
          },
          title: {
            text: "Market attractiveness",
          }
        },
        annotations: {
          yaxis: [
            {
              y: 60,
              borderColor: "red"
            }
          ],
          xaxis: [
            {
              x: 60,
              borderColor: "red"
            }
          ]
        }
      },
      chartSeries: [
        {
          name: '',
          data: [[this.totalProjectRelatedness, this.totalMarketAttractiveness]]
        }
      ],
      confirmSubmitConceptModal: false,
      submitObj: [],
      k2Obj: [],
      confirmApproveConceptModal : false,
      conceptProcessID:"",
      disabled: false,
      conceptStatus: "",
      showRejectModal: false,
      rejectModel: {
        DocumentNo: "",
        ProcessID: "",
        RejectLevel: "",
        RejectUser: "",
        RejectComment: ""
      },
      conceptNo: "",
      isSpinner:false,
      hideLoader:false
    };
  },
  props: {
    showModal: Boolean,
    item: {
      type: Object,
      default: () => {
        return {
          ConceptNo: "",
          ConceptProcessID: "",
          ConceptStatus: "",
        };
      },
    },
    //TypeConcept: String,
    
    viewmode: {
      type: String,
      default: "view",
      required: false
    },
    
    ideaDataSelected: {
      type: Array,
      required: false,
      default: () => []
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    //console.log('this.item = ', this.item)
    this.$refs.PwarningCanNotApproveCon.style.display = 'none'
    if (this.viewmode === "create") {
      this.CreateUser = this.userprofile.Username;
      this.Creator = this.userprofile.DisplayName;
      this.Email = this.userprofile.Email;
      this.CreatorBU = this.userprofile.BU;
      this.CreateDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "/")
        .replace("T", " ");

      this.DataObj = this.NewObj(null);
    }
    this.$nextTick(() => {
      this.GetBu();
      this.LoadIndustry();
      this.LoadSegment();
      this.LoadSubSegment();
      this.LoadIdea();
      this.GetGroup();
      if (this.viewmode === "create") {
        this.GetMasterScoreDetail(this.MarketScoreGroup);
        this.GetMasterScoreDetail(this.ProjectScoreGroup);
      }
    })
    // setTimeout(
    //   function() {
    //     this.GetBu();
    //     this.LoadIndustry();
    //     this.LoadSegment();
    //     this.LoadSubSegment();
    //     this.LoadIdea();
    //     this.GetGroup();
    //     if (this.viewmode === "create") {
    //       this.GetMasterScoreDetail(this.MarketScoreGroup);
    //       this.GetMasterScoreDetail(this.ProjectScoreGroup);
    //     }
    //   }.bind(this),
    //   100
    // );

    this.mydata = {
      datasets: [
        {
          label: "Concept name",
          fill: false,
          borderColor: "#f87979",
          backgroundColor: "#f87979",
          data: [
            {
              x: 40,
              y: 80
            }
          ]
        }
      ]
    };
    this.myOption = {
      responsive: true,
      maintainAspectRatio: true,
      datasets: {
        horizontalBar: {
          backgroundColor: "rgb(255, 99, 132, 0.5)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1,
          barPercentage: 1,
          categoryPercentage: 1,
          yAxisID: "bary",
          xAxisID: "barx"
        }
      },
      scales: {
        xAxes: [
          {
            type: "linear",
            position: "bottom",
            ticks: {
              min: 0, //minimum tick
              max: 100, //maximum tick
              stepSize: 10,
              reverse: false,
              beginAtZero: true
            }
          },
          {
            id: "barx",
            type: "category",
            stacked: true,
            labels: ["Project related"],
            offset: true
          }
        ],
        yAxes: [
          {
            type: "linear",
            position: "bottom",
            ticks: {
              min: 0, //minimum tick
              max: 100, //maximum tick
              stepSize: 10,
              reverse: false,
              beginAtZero: true
            }
          },
          {
            id: "bary",
            type: "category",
            stacked: true,
            labels: ["Market attractiveness"],
            offset: true
          }
        ]
      }
    };
  },
  methods: {
    GenChartSeriesName(){
      //console.log('dataobj', this.DataObj);
      let name = `${this.DataObj.ConceptName} ( ${this.totalProjectRelatedness}, ${this.totalMarketAttractiveness})`;
      return name;
    },
    CheckCanApproveCon(){
      // console.log('ideaSelected', this.ideaSelected)
      // console.log("viewmode", this.viewmode )
      // console.log("viewmode", this.DataObj )
      if(this.viewmode === "view" && this.DataObj.ConceptStatus === "Manager")
      {
        // console.log("insightData", this.ideaSelected)
        // console.log("BtnApprove", this.$refs.BtnApprove)
        // console.log("BtnReject", this.$refs.BtnReject)
        if(this.ideaSelected[0].IdeaStatus == "Completed"){
          this.$refs.BtnApproveCon.disabled = false;
          this.$refs.BtnRejectCon.disabled = false;
          this.$refs.PwarningCanNotApproveCon.style.display = 'none'
        }
        else{
          this.$refs.BtnApproveCon.disabled = true;
          this.$refs.BtnRejectCon.disabled = true;
          this.$refs.PwarningCanNotApproveCon.style.display = ''

        }
      }
    },
    AddRedStarToElement(){
        let name = ["TAIDEA", "TAToSolve", "TAcWhom", "TACurrentSolutions", "TADetailOfNew", "TAKeyResourceNeeded", "TBConceptname"];
        const tempRedStar = '<span class="error">*</span>';
        Array.from(name).forEach(item => {

          let temp = this.$refs[item].$children[0].$el.children[0].innerHTML
          if(!temp.includes('</span>'))
          {
            this.$refs[item].$children[0].$el.children[0].innerHTML = `${temp} ${tempRedStar}`
          }
        });
    },
    NewTapIdeaOrInsight(viewName, key){
       // alert("name: "+name+" doc: " +docName);
        let routeData = this.$router.resolve({name: viewName, params: {docNo: key.toString()} });
        //alert(routeData.href);
        window.open(routeData.href, '_blank');
    },
    SetViewMode(disabled) {
      this.disabled = disabled;

      let elements = document.querySelectorAll('input,textarea,button');
      elements.forEach((element) => {
        // //console.log(element.id);
        if (element.id !== "btnApprove" && element.id !== "btnReject" && element.id !== "btnCancel" && element.id !== "btnCloseModal" && 
            element.id !== "btnApproveNo" && element.id !== "btnApproveYes" && element.id !== "btnCloseApproveModal" && 
            element.id !== "txtRejectComment" && element.id !== "btnRejectOK" && element.id !== "btnRejectCancel" 
            && element.id !== "btnCloseRejectModal" && element.id !== "btnClosePreview") element.disabled = disabled;
      });
    },
    NewObj(Obj) {
      // var NewObj = {
      //   ActionUser: null,
      //   ConceptNo: Obj === null ? null : Obj.ConceptNo,
      //   ConceptCreator: Obj === null ? this.Creator : Obj.ConceptCreator,
      //   ConceptCreateDate:
      //     Obj === null ? this.CreateDate : Obj.ConceptCreateDate,
      //   ConceptCreatorEmail:
      //     Obj === null ? this.Email : Obj.ConceptCreatorEmail,
      //   ConceptCreatorBU: Obj === null ? this.CreatorBU : Obj.ConceptCreatorBU,
      //   BU:
      //      Obj === null
      //       ? null
      //       : Obj.BU === null
      //       ? null
      //       : this.buOptions[
      //           this.buOptions.findIndex(
      //             x =>
      //               x.label ===
      //               (Obj.BU.label == undefined
      //                 ? Obj.BU
      //                 : Obj.BU.label)
      //           )
      //         ],
      //   ConceptCusIndustry:
      //     Obj === null
      //       ? null
      //       : Obj.ConceptCusIndustry === null
      //       ? null
      //       : this.IndustryOptions[
      //           this.IndustryOptions.findIndex(
      //             x =>
      //               x.value ===
      //               (Obj.ConceptCusIndustry.value == undefined
      //                 ? Obj.ConceptCusIndustry
      //                 : Obj.ConceptCusIndustry.value)
      //           )
      //         ],
      //   ConceptCusSegment:
      //     Obj === null
      //       ? null
      //       : Obj.ConceptCusSegment === null
      //       ? null
      //       : this.SegmentOptions[
      //           this.SegmentOptions.findIndex(
      //             x =>
      //               x.value ===
      //               (Obj.ConceptCusSegment.value == undefined
      //                 ? Obj.ConceptCusSegment
      //                 : Obj.ConceptCusSegment.value)
      //           )
      //         ],
      //   ConceptCusSubSegment:
      //     Obj === null
      //       ? null
      //       : Obj.ConceptCusSubSegment === null
      //       ? null
      //       : this.SubSegmentOptions[
      //           this.SubSegmentOptions.findIndex(
      //             x =>
      //               x.value ===
      //               (Obj.ConceptCusSubSegment.value == undefined
      //                 ? Obj.ConceptCusSubSegment
      //                 : Obj.ConceptCusSubSegment.value)
      //           )
      //         ],

      //   ConceptIdea: Obj === null ? null : Obj.ConceptIdea,
      //   ConceptToSolve: Obj === null ? null : Obj.ConceptToSolve,
      //   ConceptWhom: Obj === null ? null : Obj.ConceptWhom,
      //   ConceptCurrentSolution:
      //     Obj === null ? null : Obj.ConceptCurrentSolution,
      //   ConceptDetailOfProd: Obj === null ? null : Obj.ConceptDetailOfProd,
      //   ConceptAttach: Obj === null ? null : Obj.ConceptAttach,
      //   ConceptNeed: Obj === null ? null : Obj.ConceptNeed,
      //   ConceptName: Obj === null ? null : Obj.ConceptName,
      //   ConceptTotalMarketScore: Obj === null ? 0 : Obj.ConceptTotalMarketScore,
      //   ConceptTotalProjectScore:
      //     Obj === null ? 0 : Obj.ConceptTotalProjectScore,

      //   CreateByUser: Obj === null ? this.CreateUser : Obj.CreateByUser,
      //   ConceptStatus: Obj === null ? "Draft" : Obj.ConceptStatus,
      //   // BU: Obj === null ? null : Obj.BU,
      //   ConceptMarketScoreGroup:
      //     Obj === null ? this.MarketScoreGroup : Obj.ConceptMarketScoreGroup,
      //     ConceptProjectScoreGroup:
      //     Obj === null ? this.ProjectScoreGroup : Obj.ConceptProjectScoreGroup,
      //   // files: Obj === null ? null : Obj.files,
      //   files: Obj === null ? [] : Obj.files === null || Obj.files === undefined ? [] : Obj.files,
      //   FlagDelete: false,
      //   IndustryDesc: Obj === null ? null : Obj.IndustryDesc,
      //   SegmentDesc: Obj === null ? null : Obj.SegmentDesc,
      //   fileExists:
      //     Obj === null ? [] : Obj.fileExists === null ? [] : Obj.fileExists,
      //   FileNames: [],
      //   MappingIdea: [],
      //   MarketAttractiveness: [],
      //   ProjectRelatedness: []
      // };

      //this.conceptStatus = NewObj.ConceptStatus;

      // console.log("NewObj.files", NewObj.files);
      if(Obj == null || Obj == undefined)
      {
        return this.GenDataDefault();
      }
      else
      {
        return this.BindDataToModel(Obj);
      }
    },
    GenDataDefault(){
      const defaultModel = {
        ActionUser: null,
        ConceptNo: null,
        ConceptCreator: this.Creator,
        ConceptCreateDate: this.CreateDate,
        ConceptCreatorEmail:  this.Email,
        ConceptCreatorBU: this.CreatorBU,
        BU: null,
        ConceptCusIndustry: null,
        ConceptCusSegment: null,
        ConceptCusSubSegment: null,

        ConceptIdea: null,
        ConceptToSolve: null,
        ConceptWhom: null,
        ConceptCurrentSolution: null,
        ConceptDetailOfProd: null,
        ConceptAttach: null,
        ConceptNeed: null,
        ConceptName: null,
        ConceptTotalMarketScore: 0,
        ConceptTotalProjectScore: 0,

        CreateByUser: this.CreateUser,
        ConceptStatus: "Draft",
        ConceptMarketScoreGroup: this.MarketScoreGroup,
        ConceptProjectScoreGroup: this.ProjectScoreGroup,
        files: [],
        FlagDelete: false,
        IndustryDesc: null,
        SegmentDesc: null,
        fileExists: [],
        FileNames: [],
        MappingIdea: [],
        MarketAttractiveness: [],
        ProjectRelatedness: []
      };

      return defaultModel;
    },
    BindDataToModel(obj){
      let tempModel = this.GenDataDefault();
        tempModel.ConceptNo = obj.ConceptNo;
        tempModel.ConceptCreator = obj.ConceptCreator;
        tempModel.ConceptCreateDate = obj.ConceptCreateDate;
        tempModel.ConceptCreatorEmail =  obj.ConceptCreatorEmail;
        tempModel.ConceptCreatorBU = obj.ConceptCreatorBU;

        if(obj.BU == null || obj.BU == undefined)
        {
          tempModel.BU = null;
        }
        else
        {

          tempModel.BU = this.buOptions[
                            this.buOptions.findIndex(
                              x => x.label === (obj.BU.label == undefined
                                      ? obj.BU
                                      : obj.BU.label)
                              )];

        }

        if(obj.ConceptCusIndustry == null || obj.ConceptCusIndustry == undefined)
        {
          tempModel.ConceptCusIndustry = null;
        }
        else
        {

          tempModel.ConceptCusIndustry = 
            this.IndustryOptions[
              this.IndustryOptions.findIndex(
                x => x.value === (obj.ConceptCusIndustry.value == undefined
                    ? obj.ConceptCusIndustry
                    : obj.ConceptCusIndustry.value)
            )];

        }

        if(obj.ConceptCusSegment == null || obj.ConceptCusSegment == undefined)
        {
          tempModel.ConceptCusSegment = null;
        }
        else
        {

          tempModel.ConceptCusSegment = this.SegmentOptions[
                                          this.SegmentOptions.findIndex(
                                            x => x.value === (obj.ConceptCusSegment.value == undefined
                                                ? obj.ConceptCusSegment
                                                : obj.ConceptCusSegment.value)
                                          )];

        }

        if(obj.ConceptCusSubSegment == null || obj.ConceptCusSubSegment == undefined)
        {
          tempModel.ConceptCusSubSegment = null;
        }
        else
        {

          tempModel.ConceptCusSubSegment = this.SubSegmentOptions[
                                              this.SubSegmentOptions.findIndex(
                                                x => x.value ===
                                                  (obj.ConceptCusSubSegment.value == undefined
                                                    ? obj.ConceptCusSubSegment
                                                    : obj.ConceptCusSubSegment.value)
                                              )];

        }

        tempModel.ConceptIdea = obj.ConceptIdea;
        tempModel.ConceptToSolve = obj.ConceptToSolve;
        tempModel.ConceptWhom = obj.ConceptWhom;
        tempModel.ConceptCurrentSolution = obj.ConceptCurrentSolution;
        tempModel.ConceptDetailOfProd = obj.ConceptDetailOfProd;
        tempModel.ConceptAttach = obj.ConceptAttach;
        tempModel.ConceptNeed = obj.ConceptNeed;
        tempModel.ConceptName = obj.ConceptName;
        tempModel.ConceptTotalMarketScore = obj.ConceptTotalMarketScore;
        tempModel.ConceptTotalProjectScore = obj.ConceptTotalProjectScore;

        tempModel.CreateByUser = obj.CreateByUser;
        tempModel.ConceptStatus = obj.ConceptStatus;
        tempModel.ConceptMarketScoreGroup = obj.ConceptMarketScoreGroup;
        tempModel.ConceptProjectScoreGroup = obj.ConceptProjectScoreGroup;

        if(obj.files == null || obj.files == undefined)
        {
          tempModel.files = null;
        }
        else
        {
          tempModel.files = obj.files;
        }

        tempModel.IndustryDesc = obj.IndustryDesc;
        tempModel.SegmentDesc = obj.SegmentDesc;

        if(obj.fileExists == null || obj.fileExists == undefined)
        {
          tempModel.fileExists = null;
        }
        else
        {
          tempModel.fileExists = obj.fileExists;
        }

        return tempModel;

    },
    LoadById(Id) {
      //console.log("LoadById", Id);
      axios
        .get(URL_GetByID, {
          params: {
            uid: Id
          }
        })
        .then(response => {
          if (response !== null) {
            //console.log("data LoadById", response.data);

            let SegmentOptions = [{ value: null, label: "--Select--" }];

            var SegmentFilter = this.SegmentList.filter(function(obj) {
              return obj.IndustryID == response.data.ConceptCusIndustry;
            });

            SegmentFilter.forEach(function(data) {
              SegmentOptions.push({
                value: data.SegmentID,
                label: data.SegmentDesc
              });
            });

            this.SegmentOptions = SegmentOptions;

            let SubSegmentOptions = [{ value: null, label: "--Select--" }];

            var SubSegmentFilter = this.SubSegmentList.filter(function(obj) {
              return obj.SegmentID == response.data.ConceptCusSegment;
            });

            SubSegmentFilter.forEach(function(data) {
              SubSegmentOptions.push({
                value: data.SubSegmentID,
                label: data.SubSegmentDesc
              });
            });

            this.SubSegmentOptions = SubSegmentOptions;

            var NewObj = this.NewObj(response.data);
            //console.log("data", response.data);
            this.DataObj.ConceptStatus = NewObj.ConceptStatus
            //console.log("MappingIdea",response.data.MappingIdea);
            //console.log("ideaDataSelected",this.ideaDataSelected);
            this.TempDataEdit = this.ChangeWordingStatus(response.data.MappingIdea);
            //this.ideaSelected = response.data.MappingIdea;
            //this.ideaDataSelected = response.data.MappingIdea;
            this.$emit('updideaListSelected-updatedatedata',response.data.MappingIdea)
            //console.log("this.ideaSelected",this.ideaSelected);
            //console.log("response LoadById", response.data);
            NewObj.MarketAttractiveness = response.data.MarketAttractiveness;
            NewObj.ProjectRelatedness = response.data.ProjectRelatedness;
            //console.log("NewObj", NewObj);
            this.marketAttractiveness = response.data.MarketAttractiveness;
            this.projectRelatedness = response.data.ProjectRelatedness;
            //console.log("marketAttractiveness", this.marketAttractiveness)
            this.CreateUser = NewObj.CreateByUser;
            this.Creator = NewObj.ConceptCreator;
            this.CreateDate = NewObj.ConceptCreateDate.substring(
              0,
              10
            ).replaceAll("-", "/");
            this.Email = NewObj.ConceptCreatorEmail;
            this.CreatorBU = NewObj.ConceptCreatorBU;

            this.LoadFilesByID(NewObj);
          }
        })
        .finally(() => {
          this.viewmode === "view" && this.SetViewMode(true);
          this.CheckCanApproveCon();
          //console.log("marketAttractiveness",this.marketAttractiveness);
          //console.log("projectRelatedness",this.projectRelatedness);

        });
    },
    CloseFromRejectModal () {
      this.SaveData("Reject");
      this.ChangeModal(true); 
    },
    ChangeModal(isReject) {
      this.viewmode === "view" && this.SetViewMode(false);
      this.DataObj = this.NewObj(null);
      this.isshow = false;
      this.TempDataEdit = [];
      if(isReject)
      {
        this.$emit("save-success");
      }
    },
    ChangeWordingStatus(ideaData){
      let tempdata = ideaData;
      Array.from(tempdata).forEach(e =>{
        if(e.IdeaStatus == "Manager"){
          e.IdeaStatus = this.$store.getters.Status.WaitApprove;
        }
        else if(e.IdeaStatus == "Request"){
          e.IdeaStatus = this.$store.getters.Status.WaitEdit;
        }
      })
      return tempdata;
    },
    searchIdea() {
      this.showIdeaSelectModal = true;
      var inputselect = document.querySelectorAll(
        'input[group="ideaCheckbox"]'
      );      
      
      inputselect.forEach(
        function(checkbox) {
          let inputid = checkbox.getAttribute("inputid");
          let currentIndexAll = this.ideaData.findIndex(
            t => t.IdeaNo == inputid
          );
          let currentIndexSelect = this.ideaSelected.findIndex(
            t => t.IdeaNo == inputid
          );

          //console.log("inputid", inputid);
          //console.log("currentIndexAll", currentIndexAll);
          //console.log("currentIndexSelect", currentIndexSelect);

          setTimeout(
            function() {
              if (currentIndexSelect > -1) {
                this.ideaData[currentIndexAll].IsSelected = true;
                checkbox.checked = true;
              } 
              else {
                this.ideaData[currentIndexAll].IsSelected = false;
                checkbox.checked = false;
              }
            }.bind(this),
            500
          );
        }.bind(this)
      );

      /*
      if (this.ideaSelected.length > 0) {
        

        this.ideaSelected.forEach(obj => {
          let currentIndex = this.ideaData.findIndex(
            t => t.IdeaNo == obj.IdeaNo
          );
          if (currentIndex > -1) {
            this.ideaData[currentIndex].IsSelected = obj.IsSelected;
          }
        });
      }
      */
    },
    ideaConfirmselected() {
      //this.ideaSelected = this.ideaData.filter(c => c.IsSelected === true);
      this.TempDataEdit = this.ideaData.filter(c => c.IsSelected === true);
      this.showIdeaSelectModal = false;
    },
    
    ideaUpdateIsSelected(id, event) {
      
      let isCheck = false;
      var inputselect = document.querySelectorAll(
        'input[group="ideaCheckbox"]'
      );

      inputselect.forEach(
        function(checkbox) {
          let inputid = checkbox.getAttribute("inputid");
          let currentIndex = this.ideaData.findIndex(t => t.IdeaNo == inputid);


          if (inputid !== id) {
            checkbox.checked = false;
            if (currentIndex > -1) {
              this.ideaData[currentIndex].IsSelected = false;
            }
          } else {
            //checkbox.checked = true;
            if (currentIndex > -1) {
              this.ideaData[currentIndex].IsSelected = isCheck = event.target.checked;
            }
          }
        }.bind(this)
      );

      if(isCheck){
        this.$refs.ideaTable.$children[0].$el.style.display = 'none'; // paging
        this.$refs.ideaTable.$el.children[0].children[0].children[1].readOnly = true // filter
      }
      else
      {
        this.$refs.ideaTable.$children[0].$el.style.display = '';
        this.$refs.ideaTable.$el.children[0].children[0].children[1].readOnly = false
      }
    },
    getBadge(status) {
      switch (status) {
        case "Approve":
          return "success";
        default:
          "primary";
      }
    },
    clickScore(groupName, score, obj) {
      //console.log("groupName",groupName);
      //console.log("score","'" + score + "'");
      var mybtn = document.querySelectorAll(
        "button[groupname=" + groupName + "]"
      );

      mybtn.forEach(function(btns) {
        btns.classList.remove("btnSelectedScore");
        if (btns.innerText == score) {
          btns.classList.add("btnSelectedScore");
          obj.ScoreSelect = score;
        }
      });
      // console.log(this.marketAttractiveness);
      // console.log(this.projectRelatedness);
    },
    ReScore(isMarket) {
      // console.log('s1')
      // console.log(this.marketAttractiveness)
      if(isMarket)
      {
        this.marketAttractiveness.forEach(function(item){
          item.ScoreSelect = 0
        })
      }
      else
      {
        this.projectRelatedness.forEach(function(item){
          item.ScoreSelect = 0
        })
      }


      // console.log('b')
      // console.log(this.totalMarketAttractiveness)
      // console.log(this.marketAttractiveness)
    },
    GetBu(){
      axios.get(URL_GetBu).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buOptions = options;
      });
    },
    LoadIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            Options.push({
              value: data.IndustryID,
              label: data.IndustryDesc
            });
          });
        }
        this.IndustryOptions = Options;
      });
    },
    LoadSegment() {
      axios.get(store.getters.URL + "/api/MasterSegment/getall").then(res => {
        //console.log("MasterSegment",res);
        if (res !== null) {
          this.SegmentList = res.data;
        }
      });
    },
    IndustryOnChange(event) {
      let Options = [{ value: null, label: "--Select--" }];
      if (event !== null) {
        var Filter = this.SegmentList.filter(function(obj) {
          return obj.IndustryID == event.value;
        });

        if (event.label.toLowerCase() != "others" &&
            Filter != null &&
            Filter.length > 0
        ) {

          let ohterFilter = this.SegmentList.filter(function(obj) {
            return obj.SegmentDesc.toLowerCase() == "others";
          });

          if (ohterFilter != null && ohterFilter.length > 0) {
            Filter.push(ohterFilter[0]);
          }
        }

        Filter.forEach(function(data) {
          Options.push({
            value: data.SegmentID,
            label: data.SegmentDesc
          });
        });

        //
        //console.log("GetMasterScoreDetail");
        // this.GetMasterScoreDetail(this.MarketScoreGroup);
        // this.GetMasterScoreDetail(this.ProjectScoreGroup);
      }

      this.SegmentOptions = Options;
      this.SubSegmentOptions = [{ value: "", label: "-- Select --" }];
      this.DataObj.ConceptCusSegment = "";
      this.DataObj.ConceptCusSubSegment = "";
    },
    LoadSubSegment() {
      axios
        .get(store.getters.URL + "/api/MasterSubSegment/getall")
        .then(res => {
          //console.log("MasterSegment",res);
          if (res !== null) {
            this.SubSegmentList = res.data;
          }
        });
    },
    SegmentOnChange(event) {
      let Options = [{ value: null, label: "--Select--" }];
      if (event !== null) {
        var Filter = this.SubSegmentList.filter(function(obj) {
          return obj.SegmentID == event.value;
        });

        if (event.label.toLowerCase() != "others" &&
            Filter != null &&
            Filter.length > 0
        ) {
          let ohterFilter = this.SubSegmentList.filter(function(obj) {
          return obj.SubSegmentDesc.toLowerCase() == "others";
          });

          if (ohterFilter != null && ohterFilter.length > 0) {
            Filter.push(ohterFilter[0]);
          }
        }

        Filter.forEach(function(data) {
          Options.push({
            value: data.SubSegmentID,
            label: data.SubSegmentDesc
          });
        });
      }

      this.SubSegmentOptions = Options;
      this.DataObj.ConceptCusSubSegment = "";
    },
    LoadIdea() {
      axios.get(store.getters.URL + "/api/IdeationIdea/getalldetail", { params: {username : this.CreateUser}}).then(res => {
        //console.log("IdeationIdea", res);
        if (res !== null) {
          this.ideaData = res.data;
          this.ideaData = this.ChangeWordingStatus(res.data)
        }
      });
    },
    GetGroup() {
      axios.get(URL_GetGroup).then(res => {
        let options = [{ value: "", label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            options.push({
              value: data.GroupID,
              label: data.GroupDesc
            });
          });
        }
        this.groupOptions = options;
      });
    },
    OpenFile(file, isExist) {
     // window.open(URL_OpenFile + "?filename=" + filename);
      if(isExist)
      {
        axios
          .get(URL_OpenFile + `?filename=${file}`,{headers: {"responseType": "arraybuffer"}})
          .then((response) => {
            if (response.status === 200) {
              let result = this.CheckOpenFile(response.data, isExist) ;//global Func
              if(result.isImage)
              {
                this.urlImage = result.urlImage;
                this.previewImgModal = true;
              }

            } else {
              this.$_toast_Noti("error", "error", response.data.Message);
            }
          })
          .catch((error) => {
            this.$_toast_Noti("error", "error", error);
          });
      }
      this.urlImage = null;
      let result = isExist? this.CheckOpenFile(null) : this.CheckOpenFile(file);//global Func
      if(result.isImage)
      {
        this.urlImage = result.urlImage;
        this.previewImgModal = true;
      }
    },
    fileChange(fileList) {
      var IsValidate = true;

      Array.from(fileList).forEach(function (file) {
          //console.log("thisfile",file);
          if(file.size > 5242880){
            this.$_toast_Noti("error", "error", "Cannot upload file more than 5 MB");
            IsValidate = false;
          }
      }.bind(this));

      if(IsValidate){
        // this.DataObj.files = fileList;
        Array.from(fileList).forEach((file) => {
          if (this.DataObj.files.findIndex((f) => f.name == file.name) === -1) {
            this.DataObj.files.push(file);
          }
        });

        document.getElementById(this.$refs['inputfile'].safeId).value = null;
      }

      // console.log("files", this.DataObj.files);
    },
    GenerateFileName(filename) {
      return (
        this.$uuid.v4() + "/" + filename //+ "." + filename.substr(filename.lastIndexOf(".") + 1)
      );
    },
    UploadFile(formData) {
      // Upload File
      axios
        .post(URL_Upload, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .catch(error => {
          //console.log("error",error);
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {
          //console.log("success");
          this.$_toast_Noti("success", "success", "");
          this.ChangeModal();
        });
    },
    LoadFilesByID(Obj) {
      //console.log("LoadFilesByID", Obj);
      var fileExists = [];
      axios
        .get(URL_GetFilesByDoc, {
          params: {
            DocumentID: Obj.ConceptNo,
            Path: store.getters.URL
          }
        })
        .then(response => {
          if (response !== null) {
            //console.log("LoadFilesByID", response.data);
            Obj.fileExists = response.data;
          }
        })
        .finally(() => {
          this.DataObj = Obj;
        });
    },
    ConfirmDeleteFileBeforeSave(key) {
      if (confirm("Do you want to delete?")) {
        this.DataObj.files.splice(key, 1);
      }
    },
    ConfirmDeleteFile(file) {
      if (confirm("Do you want to delete?")) {
        file.IsDelete = true;
      }
    },
    GetMasterScoreDetail(group, bu) {
      let result = [];
      //console.log("IdeaIndustry",this.DataObj.ConceptCusIndustry);
      var IdeaIndustryValue = null;
      if(this.DataObj.ConceptCusIndustry !== null && this.DataObj.ConceptCusIndustry !== undefined){            
        IdeaIndustryValue = this.DataObj.ConceptCusIndustry;
        if(this.DataObj.ConceptCusIndustry.value !== undefined){
          IdeaIndustryValue = this.DataObj.ConceptCusIndustry.value;
        }
      }

      //console.log("IdeaIndustryValue",IdeaIndustryValue);
      // url:/api/MasterScoreDetail/getbygroup
      axios
        .get(store.getters.URL + "/api/MasterScoreDetail/GetByGroupAndBu", {
          params: {
            group: group,
            bu: bu
            //,IndustryID: IdeaIndustryValue
          }
        })
        .then(response => {
          //console.log("GetMasterScoreDetail",response);

          if (group === "Market") {
            this.marketAttractiveness = response.data;
          } else if (group === "Project") {
            this.projectRelatedness = response.data;
          }
        })
        .catch(error => {
          //console.log("error", error);
        });
    },
    SaveData(action) {
      //console.log("this.DataObj",this.DataObj);
      if(action != 'Save')
      {
        let isNull = false;
        if(this.DataObj.BU == "" || this.DataObj.BU == null || this.DataObj.BU == undefined)
        {
          isNull = true;
        }
        else
        {
          if(this.DataObj.BU.value == "")
          {
            isNull = true;
          }
          
        }

        if(isNull)
        {
          this.$_toast_Noti('error','error', 'Please select relation bu !');
          return;
        }
      }
      var modal = this.$el.getElementsByClassName(this.ModalName);
      var forms = modal[0].querySelectorAll('[required="required"]');

      //console.log("this.ideaSelected.length",this.ideaSelected);
      var validation = true
      Array.prototype.slice.call(forms)
      .forEach(function (form) {

        var vs__dropdown = form.getElementsByClassName("vs__dropdown-toggle")[0];


        if(vs__dropdown !== "" && vs__dropdown !== null && vs__dropdown !== undefined){
          var vs__selected = vs__dropdown.getElementsByClassName("vs__selected")[0];
          if(vs__selected === "" || vs__selected === null || vs__selected === undefined){
            vs__dropdown.style.borderColor = "#ea5455";
            form.scrollIntoView(true);
            validation = false;
            return;
          }
          else{
            //console.log("vs__selected.text",vs__selected.textContent.trim());
            if(vs__selected.textContent.trim() === "-- Select --"){
              vs__dropdown.style.borderColor = "#ea5455";
              form.scrollIntoView(true);
              validation = false;
              return;
            }
            else{
              vs__dropdown.style.borderColor = "";
            }
            
          }
        }
        else if(form.value === "" || form.value === null || form.value === undefined){
          form.focus();
          validation = false;
          return;
        }
        
      });
      if(validation && this.ideaSelected.length <= 0){
        validation = false;
        this.$_toast_Noti("error", "Please select idea", "");
        this.searchIdea();
      }
      if(validation){
        let saveObj = this.NewObj(this.DataObj);
        if(saveObj.ConceptCusIndustry == null || saveObj.ConceptCusIndustry == undefined|| saveObj.ConceptCusIndustry == "" )
        {
          this.$_toast_Noti('error','error', 'Please select industry !');
          return;
        }
        saveObj.ActionUser = this.userprofile.DisplayName
        saveObj.ConceptCreateDate = null;//new Date(saveObj.ConceptCreateDate);
        if(saveObj.ConceptStatus === 'Draft' && action === 'Submit')
        {
          saveObj.ConceptStatus = 'Draft,Submit';
        }
        else
        {
          saveObj.ConceptStatus = action === 'Save' ? 'Draft' : action;
        }

        if (saveObj.BU != null) {
          saveObj.BU =
            saveObj.BU.value === undefined
              ? saveObj.BU
              : saveObj.BU.label;
        }
        if (saveObj.ConceptCusIndustry != null) {
          saveObj.ConceptCusIndustry =
            saveObj.ConceptCusIndustry.value === undefined
              ? saveObj.ConceptCusIndustry
              : saveObj.ConceptCusIndustry.value;
        }

        if (saveObj.ConceptCusSegment != null) {
          saveObj.ConceptCusSegment =
            saveObj.ConceptCusSegment.value === undefined
              ? saveObj.ConceptCusSegment
              : saveObj.ConceptCusSegment.value;
        }

        if (saveObj.ConceptCusSubSegment != null) {
          saveObj.ConceptCusSubSegment =
            saveObj.ConceptCusSubSegment.value === undefined
              ? saveObj.ConceptCusSubSegment
              : saveObj.ConceptCusSubSegment.value;
        }
        saveObj.ConceptTotalMarketScore = this.totalMarketAttractiveness;
        saveObj.ConceptTotalProjectScore = this.totalProjectRelatedness;

        saveObj.MappingIdea = this.ideaSelected;
        saveObj.MarketAttractiveness = this.marketAttractiveness;
        saveObj.ProjectRelatedness = this.projectRelatedness;
        
        if (this.viewmode === "create") {
          this.InsertData(saveObj, action);
          this.confirmSubmitConceptModal = false;
          this.confirmApproveConceptModal = false;
        } 
        else { //if (this.viewmode === "edit")
          this.UpdateData(saveObj, action);
          this.confirmSubmitConceptModal = false;
          this.confirmApproveConceptModal = false;
        }
      }
      
    },
    InsertData(saveObj, action) {
      //console.log("action",action);
      //console.log("URL_Insert", URL_Insert);
      //console.log("saveObj", saveObj);
      this.hideLoader = true;
      
      axios
        .put(URL_Insert, saveObj)
        .then(response => {
          if (response !== null) {
            if (response.status === 200) {
              //console.log("response con", response);

              saveObj.ConceptNo = response.data.ConceptNo;
              this.$emit("save-success");
              // this.k2Obj.push({
              //   DocumentNO: saveObj.ConceptNo,
              //   CreatorName: this.userprofile.DisplayName,
              //   UserCreator: this.userprofile.Username,
              //   Action: "Submit"
              // });
              if(response.data.IsError)
              {
                this.$_toast_Noti("warn", "warn", response.data.Message[0])
              }
              else
              {
               this.$_toast_Noti("success", "success", "");
              }
            }
          }
        })
        .catch(error => {
          //console.log("error",error);
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {

          //this.ConceptObject = saveObj;

          if (saveObj.files !== null && saveObj.files !== undefined) {
            let formData = new FormData(); // Create FormData for upload file
            saveObj.files.forEach(file => {
              let filename = this.GenerateFileName(file.name); // Create file name
              //file.FileNames.push(filename); // Add file name to list
              formData.append(saveObj.ConceptNo, file, filename); // Add file for upload
            });

            // Upload File
            this.UploadFile(formData);
          } 
          this.hideLoader = false
          this.ChangeModal();
          // ====> K2
          // if (action === "Submit" && this.k2Obj.length > 0) {
          //   this.ConfirmK2Service(this.k2Obj);
          //   //console.log("submit", this.submitObj);
          //   //submit K2//
          // } else {
          //   this.ChangeModal();
          // }
          // ====> K2
        });
    },
    UpdateData: function(saveObj, action) {
      this.hideLoader = false;
      
      axios
        .post(URL_Update, saveObj)
        .then(response => {
          if (response.status === 200) {
            this.$emit("save-success");
            if(response.data.IsError)
            {
              this.$_toast_Noti("warn", "warn", response.data.Message[0])
            }
            else
            {
              this.$_toast_Noti("success", "success", "");
            }
          }
          //this.InsightObject = saveObj;
          //console.log("response", response);
        })
        .catch(error => {
          //console.log("error", error);
          this.$_toast_Noti("error", "error", error);
        })
        .finally(() => {

          //this.ConceptObject = saveObj;
          
          if (saveObj.files !== null && saveObj.files !== undefined) {
            //console.log("saveObj", saveObj.files);

            let formData = new FormData(); // Create FormData for upload file

            saveObj.files.forEach(file => {
              let filename = this.GenerateFileName(file.name); // Create file name
              saveObj.FileNames.push(filename); // Add file name to list
              formData.append(saveObj.ConceptNo, file, filename); // Add file for upload
            });

            // Upload File
            this.UploadFile(formData);
          } 
          //else {       
          this.hideLoader = false

          this.ChangeModal();
          //}

          // ======> K2
          // if (action === "Submit") {
          //   this.k2Obj.push({
          //     DocumentNO: saveObj.ConceptNo,
          //     CreatorName: saveObj.ConceptCreator,
          //     UserCreator: saveObj.CreateByUser,
          //     Action: "Submit"
          //   });
          //   this.ConfirmK2Service(this.k2Obj);
          //   //Submit K2//
          // }
          // else if(action === "Approve"){
          //     this.k2Obj.push({
          //       Username: this.userprofile.Username,
          //       Token: this.userToken.token,
          //       ProcessID: this.conceptProcessID,
          //       Action: action,
          //     });
          //     this.ConfirmK2Service(this.k2Obj);
          //     //Approve K2//
          // }
          // else{
          //   this.ChangeModal();
          // }
          // ======> K2
        });
    },
    DeleteData: function() {
      if (confirm("Do you want to delete?")) {
        let saveObj = this.NewObj(this.DataObj);

        if (saveObj.ConceptCusIndustry != null) {
          saveObj.ConceptCusIndustry =
            saveObj.ConceptCusIndustry.value === undefined
              ? saveObj.ConceptCusIndustry
              : saveObj.ConceptCusIndustry.value;
        }

        if (saveObj.ConceptCusSegment != null) {
          saveObj.ConceptCusSegment =
            saveObj.ConceptCusSegment.value === undefined
              ? saveObj.ConceptCusSegment
              : saveObj.ConceptCusSegment.value;
        }

        if (saveObj.ConceptCusSubSegment != null) {
          saveObj.ConceptCusSubSegment =
            saveObj.ConceptCusSubSegment.value === undefined
              ? saveObj.ConceptCusSubSegment
              : saveObj.ConceptCusSubSegment.value;
        }

        saveObj.ConceptTotalMarketScore = this.totalMarketAttractiveness;
        saveObj.ConceptTotalProjectScore = this.totalProjectRelatedness;

        saveObj.MappingIdea = this.ideaSelected;
        saveObj.MarketAttractiveness = this.marketAttractiveness;
        saveObj.ProjectRelatedness = this.projectRelatedness;

        axios
          .post(URL_Delete, null, { params: { conceptNo: saveObj.ConceptNo } })
          .then(response => {
            //console.log("response", response);
            //saveObj.FlagDelete = true;
            //this.ConceptObject = saveObj;
            if (response.status === 200) {
              this.$emit("save-success");
              this.$_toast_Noti("success", "success", "");
            }
          })
          .catch(error => {
            //console.log("error", error);
            this.$_toast_Noti("error", "error", error);
          })
          .finally(() => {
            //this.ChangeModal();
            this.isshow = false;
            this.InsightList = [];
            this.item = null;
          });

      }
    },
    K2Action(action) {
      switch (action) {
        case "Submit":
          //this.k2Obj.splice(0);
          this.confirmSubmitConceptModal = true;
          break;
        case "Approve":
            //this.k2Obj.splice(0);
            this.confirmApproveConceptModal = true;
            break;
        case "Reject":
          this.rejectModel = { 
            DocumentNo : this.conceptNo,
            ProcessID : this.conceptProcessID,
            RejectLevel : "Manager",
            RejectUser : this.userprofile.DisplayName,
            RejectComment : ""
           };
          this.showRejectModal = true;
          break;
        default:
          break;
      }
    },
    ConfirmK2Service(k2Obj) {
      this.hideLoader = true
      //console.log("k2Obj",k2Obj);
      switch(k2Obj[0].Action){
        case "Submit":
          //console.log("ObjSubmit", k2Obj);
          axios.put(store.getters.URL + '/api/IdeationConcept/submitconcept',k2Obj).then(res => {
              if (res.data.StatusCode === 200) {
                if (res.data.Message === "Success") {
                  this.$_toast_Noti("success","success","Your concept was submitted.");
                  this.ChangeModal()
                  this.confirmSubmitConceptModal = false;     
                  this.$emit("save-success");             
                }
                else if(res.data.Message === "Error"){
                    this.$_toast_Noti("error","error","Cannot submit concept.Please try again later.!");
                    this.confirmSubmitConceptModal = false;
                }
                else{
                this.$_toast_Noti("error","error",res.data.Message);
                this.confirmSubmitConceptModal = false;
                //console.log("Error",res.data.Message)
                }
              }
              else{
                this.$_toast_Noti("error","error",res.data.Message);
                //console.log("Error",res.data.Message)
              }
              this.hideLoader = false
          }).catch(err => {
            console.error("ErrorRes", err.response);
            this.$_toast_Noti("error","error",err.response);
            this.confirmSubmitConceptModal = false;
            this.hideLoader = false
          })
          break
          case "Approve":
            //console.log("ObjApprove", k2Obj);
            axios.put(store.getters.URL + '/api/IdeationConcept/approveconcept',k2Obj).then(res => {
              if (res.data.StatusCode === 200) {
                if (res.data.Message === "Success") {
                  this.$_toast_Noti("success","success","Your concept was approved.");
                  this.confirmApproveConceptModal = false;
                  //this.ConfirmConceptModal = true;

                  setTimeout(
                    function() {

                      this.$emit("save-success");
                      this.hideLoader = false
                      this.ChangeModal();

                    }.bind(this),
                    7777 
                  );

                }
                else if(res.data.Message === "Error"){
                    this.$_toast_Noti("error","error","Cannot approve concept.Please try again later.!");
                    this.confirmApproveConceptModal = false;
                    this.hideLoader = false;
                }else{
                  this.$_toast_Noti("error","error",res.data.Message);
                  this.confirmApproveConceptModal = false;
                  this.hideLoader = false;
                }
              }
              else{
                this.$_toast_Noti("error","error",res.data.Message);
                this.confirmApproveConceptModal = false;
                console.error(res.data.Message)
                this.hideLoader = false;
              }
            }).catch(err => {
              this.$_toast_Noti("error","error",err.response);
              this.confirmApproveConceptModal = false;
              console.error(err)
              this.hideLoader = false              
            })              
            break
            default:
              break
      }      
    },
    CancelSubmit() {
      this.confirmSubmitConceptModal = false;
      //this.ConfirmIdeaModal = true;
    },
    CancelApprove(){
      this.confirmApproveConceptModal = false;
    },
    CheckIndustrySelect()
    {
      let name = 'RefConcepVSelect'
      if(this.$refs[name] == undefined)
      {
        return;
      }
      if(this.DataObj.ConceptCusIndustry == null)
      {
        this.$refs[name].$el.children[0].style.borderColor = "red"
      }
      else
      {
        this.$refs[name].$el.children[0].style.borderColor = ""
      }
    },
    CheckRelateBuSelect()
    {
      let name = 'RefRelateBuSelect'
      if(this.$refs[name] == undefined)
      {
        return;
      }
      if(this.DataObj.BU == null)
      {
        this.$refs[name].$el.children[0].style.borderColor = "red"
      }
      else
      {
        this.$refs[name].$el.children[0].style.borderColor = ""
      }
    },
    CheckElementNull(data, IsaArray = false)
    {
      if(IsaArray){
        return data.length === 0
      }
      else{
        return data === "" || data === null || data === undefined
      }
    },
    ValidateElement(data)
    {
        const a = this.CheckElementNull(data.ConceptCusIndustry);
        const b = this.CheckElementNull(data.ConceptIdea);
        const c = this.CheckElementNull(data.ConceptToSolve);
        const d = this.CheckElementNull(data.ConceptWhom);
        const e = this.CheckElementNull(data.ConceptCurrentSolution);
        const f = this.CheckElementNull(data.ConceptDetailOfProd);
        const g = this.CheckElementNull(data.ConceptNeed);
        const h = this.CheckElementNull(data.ConceptName);
        const i = this.CheckElementNull(this.TempDataEdit, true);
        const j = this.CheckElementNull(this.ideaDataSelected, true);
        const k = (i == false || j == false)? false : true
        const l =this.CheckElementNull(data.BU)

      return [a,b,c,d,e,f,g,h,k,l].every( e => e === false)
    },
    ValidateStateShowChart()
    {
      if(this.chartSeries[0].data.length > 0)
      {
        if(this.chartSeries[0].data[0][0] != 0 || this.chartSeries[0].data[0][1] != 0)
        {
          this.showChart = true
        }
        else 
        {
          this.showChart = false
        }
      }
    },
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("ConceptModal-updated", v);
      }
    },
    ideaSelected: {
      get() {
        return this.TempDataEdit.length === 0
          ? this.ideaDataSelected
          : this.TempDataEdit;
      },
      set(v) {
        this.$emit("ideaListSelected-updated", v);
      }
    },
    ConceptDataByIndustry() {
      return [
        {
          label: "Industry",
          backgroundColor: "#EF5350",
          data: [55, 20, 12, 39, 75]
        }
      ];
    },
    totalMarketAttractiveness() {
      return this.marketAttractiveness.reduce((total, item) => {
        return total + item.ScoreSelect * item.ScoreWeight;
      }, 0);
    },
    totalProjectRelatedness() {
      return this.projectRelatedness.reduce((total, item) => {
        return total + item.ScoreSelect * item.ScoreWeight;
      }, 0);
    },
    userRole: function () {
      return this.userprofile.Role;
    },
    ConceptObject: {
      get() {
        return this.item;
      },
      set(v) {
        this.$emit("ConceptObject-updated", v);
      }
    },
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if(newVal == true)
      {
        this.AddRedStarToElement()
        this.CheckIndustrySelect()
        this.CheckRelateBuSelect()
      }
    },
    DataObj: {
      handler: function(newValue) {
        this.CheckIndustrySelect()
        this.CheckRelateBuSelect()
        if(this.ValidateElement(newValue))
        {
          let bu = newValue.BU.value
          if(
            newValue.ConceptStatus == "Manager"||
            newValue.ConceptStatus == "Completed"|| 
            newValue.ConceptStatus == "Request"||
            newValue.ConceptStatus == "Draft"
          ) {
            this.marketAttractiveness = newValue.MarketAttractiveness;
            this.projectRelatedness = newValue.ProjectRelatedness;

            if(this.marketAttractiveness.length == 0)
            {
              this.GetMasterScoreDetail(this.MarketScoreGroup,bu);
            }

            if(this.projectRelatedness.length == 0)
            {
              this.GetMasterScoreDetail(this.ProjectScoreGroup,bu);
            }

          }
          else if(this.marketAttractiveness.length == 0)
          {
            this.GetMasterScoreDetail(this.MarketScoreGroup,bu);
            this.GetMasterScoreDetail(this.ProjectScoreGroup,bu);
          }
        }
        else
        {
          this.marketAttractiveness = [];
          this.projectRelatedness = [];
        }
      },
      deep: true
    },
    totalMarketAttractiveness: function(newVal, oldVal) {
      // watch it
      if (newVal !== null) {
        this.chartSeries = [
          {
            name: this.GenChartSeriesName(),
            data: [[this.totalProjectRelatedness, newVal]]
          }
        ];
        this.ValidateStateShowChart()
      }
    },
    totalProjectRelatedness: function(newVal, oldVal) {
      // watch it showChart
      if (newVal !== null) {
        this.chartSeries = [
          {
            name: this.GenChartSeriesName(),
            data: [[newVal , this.totalMarketAttractiveness]]
          }
        ];
        this.ValidateStateShowChart()
      }
    },
    item: function(newVal, oldVal) {
      // watch it
      //console.log('item', item)
      if (newVal !== null) {    
        setTimeout(
          function() {
            //console.log('LoadById');
            this.LoadById(newVal.ConceptNo);
            this.conceptNo = newVal.ConceptNo;
            this.conceptProcessID = newVal.ConceptProcessID;
            //console.log("ConceptStatus",newVal.ConceptStatus)
            // if(this.viewmode === "view" && newVal.ConceptStatus == "Manager")
            // {
            //   this.SetViewMode(true);
            // }
          }.bind(this),
          500
        );
      }
    },
    ideaSelected: function(newVal, oldVal) {
      // watch it
      //console.log('item', item)
      //console.log("Prop changed: ", newVal, " | was: ", oldVal);
      if (newVal !== null) {
        if (newVal.length > 0) {
          //newVal = this.ChangeWordingSataus(newVal);
          let currentIndexAll = this.ideaData.findIndex(
            t => t.IdeaNo == newVal[0].IdeaNo
          );

          if (currentIndexAll === -1) {
            newVal[0].IsSelected = true;
            this.ideaData.push(newVal[0])
          } 

          if(this.viewmode == "create")
          {
            this.SegmentOptions = [{ value: null, label: "--Select--" }];
            this.SubSegmentOptions = [{ value: null, label: "--Select--" }];
            this.DataObj.ConceptCusSegment = null;
            this.DataObj.ConceptCusSubSegment = null;

            this.DataObj.ConceptCusIndustry = (this.IndustryOptions[this.IndustryOptions.findIndex(x => x.value === newVal[0].IdeaIndustry)]);
            
            // this.GetMasterScoreDetail(this.MarketScoreGroup);
            // this.GetMasterScoreDetail(this.ProjectScoreGroup);

            if(newVal[0].IdeaSegment !== null && newVal[0].IdeaSegment !== undefined){
              
              let Options = [{ value: null, label: "--Select--" }];

              var Filter = this.SegmentList.filter(function(obj) {
                return obj.IndustryID == newVal[0].IdeaIndustry;
              });

              Filter.forEach(function(data) {
                Options.push({
                  value: data.SegmentID,
                  label: data.SegmentDesc
                });
              });
              this.SegmentOptions = Options
              this.DataObj.ConceptCusSegment = (this.SegmentOptions[this.SegmentOptions.findIndex(x => x.value === newVal[0].IdeaSegment)]);
            }

            if(newVal[0].IdeaSubSegment !== null && newVal[0].IdeaSubSegment !== undefined){
              
              let Options = [{ value: null, label: "--Select--" }];

              var Filter = this.SubSegmentList.filter(function(obj) {
                return obj.SegmentID == newVal[0].IdeaSegment;
              });

              Filter.forEach(function(data) {
                Options.push({
                  value: data.SubSegmentID,
                  label: data.SubSegmentDesc
                });
              });

              this.SubSegmentOptions = Options
              this.DataObj.ConceptCusSubSegment = (this.SubSegmentOptions[this.SubSegmentOptions.findIndex(x => x.value === newVal[0].IdeaSubSegment)]);
            }
          }
          //console.log('newVal[0]', newVal[0]);
          this.DataObj.ConceptIdea = newVal[0].IdeaDetail;
          this.DataObj.ConceptToSolve = newVal[0].IdeaToSolve;
          this.DataObj.ConceptWhom = newVal[0].IdeaWhom;
          
          //console.log("IdeaIndustry",newVal[0].IdeaIndustry)

          var IdeaIndustryValue = null;
          if(newVal[0].IdeaIndustry !== null && newVal[0].IdeaIndustry !== undefined){            
            IdeaIndustryValue = newVal[0].IdeaIndustry;
            if(newVal[0].IdeaIndustry.value !== undefined){
              IdeaIndustryValue = newVal[0].IdeaIndustry.value;
            }
          }

          var IdeaGroupValue = null;
          if(newVal[0].IdeaGroup !== null && newVal[0].IdeaGroup !== undefined){            
            IdeaGroupValue = newVal[0].IdeaGroup;
            if(newVal[0].IdeaGroup.value !== undefined){
              IdeaGroupValue = newVal[0].IdeaGroup.value;
            }
          }
          
          if(newVal[0].IdeaEventFlag){
            this.showIdeationEvent = true;
            this.IdeaGroup = (this.groupOptions[this.groupOptions.findIndex(x => x.value === IdeaGroupValue)]);
            this.IdeaIndustry = (this.IndustryOptions[this.IndustryOptions.findIndex(x => x.value === newVal[0].IdeaIndustry)]);
            this.IdeaEventDate = newVal[0].IdeaEventDate.toString();
          }
          else{
            this.showIdeationEvent = false;
          }

        }
      }
    },
    showIdeaSelectModal: function(newVal, oldVal){
      if(newVal == false)
      {
        this.$refs.ideaTable.$children[0].$el.style.display = '';
        this.$refs.ideaTable.$el.children[0].children[0].children[1].readOnly = false
      }
    },
  }
};
</script>
<style scoped>
  .tableInsightdetail td
  {
    border: none !important;
  }
</style>